/* global process */
import mockData from "./mocks/issuer-list";

export default async function getIssuerList() {
  if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData);
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/issuers?`);
  return await response.json();
}
