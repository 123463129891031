import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Box, InputLabel, FormControl, Select, MenuItem, TextField } from "@mui/material";
import FileSelector from "../components/FileSelector";
import LobSelect from "../components/LobSelect";
import IssuerSelect from "../components/IssuerSelect";

function FormRowGenerator({ thisField, control }) {
  if (thisField.type === "file") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <FileSelector
                {...field}
                label={thisField.label}
                variant="outlined"
                fullWidth
                required={thisField.required}
              />
            </FormControl>
          )}
        />
      </Box>
    );
  }

  if (thisField.type === "select") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-label">{thisField.label}</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                {...field}
                label={thisField.label}
                variant="outlined"
                fullWidth
                required={thisField.required}
              >
                {thisField.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
    );
  }
  if (thisField.type === "lob") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <>
              <FormControl fullWidth>
                <InputLabel id="select-label">{thisField.label} *</InputLabel>
                <LobSelect {...field} required={thisField.required} label={thisField.label} />
              </FormControl>
            </>
          )}
        />
      </Box>
    );
  }
  if (thisField.type === "issuer") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <>
              <FormControl fullWidth>
                <InputLabel id="select-label">{thisField.label} *</InputLabel>
                <IssuerSelect {...field} required={thisField.required} label={thisField.label} />
              </FormControl>
            </>
          )}
        />
      </Box>
    );
  }
  if (thisField.type === "text") {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Controller
          name={thisField.name}
          control={control}
          rules={{ required: thisField.required }}
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                {...field}
                label={thisField.label}
                variant="outlined"
                fullWidth
                required={thisField.required}
              />
            </FormControl>
          )}
        />
      </Box>
    );
  }
  return <div>I don&apos;t know what to do with this field type: {thisField.type}</div>;
}

FormRowGenerator.propTypes = {
  thisField: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    required: PropTypes.bool,
    options: PropTypes.array,
  }),
  control: PropTypes.object,
};

export default FormRowGenerator;
