/* global process */
import mockData from "./mocks/jobs-complete.js";

const fetchHistoryChecks = async () => {
  if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData.data);
      }, 1000);
    });
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/jobs?status=COMPLETE`);
  const result = await response.json();
  return result.data;
};
export default fetchHistoryChecks;
