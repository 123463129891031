import "./App.css";
import "./styles/styleOverride.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import routesConfig from "./routes";
import { msalConfig } from "./authConfig";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const msalInstance = new PublicClientApplication(msalConfig);

const router = createBrowserRouter(routesConfig);

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <div className="App" data-testid="app-container">
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
