/* global process */
import mockData from "./mocks/job-comparison-results-by-comparisonid";

const fetchFilesByComparisonId = async (comparisonId) => {
  if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData.data);
  }
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}api/job-comparison-results-by-comparisonid?comparison_id=${comparisonId}`
  );
  const data = await response.json();
  return data.data;
};

export default fetchFilesByComparisonId;
