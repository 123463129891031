/* global process */

import { useMutation } from "@tanstack/react-query";

const useJobCreationMutation = () => {
  return useMutation({
    mutationFn: (newTodo) => {
      if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: "error",
              message: "job created successfully.",
              data: newTodo,
            });
          }, 3000);
        });
      }
      const formData = new FormData();
      const submitable = {
        ...newTodo,
      };
      submitable.files = [];
      newTodo.files.forEach((fRow) => {
        console.log("fRow", fRow);
        formData.append("files", fRow.file_content);
        submitable.files.push({
          file_name: fRow.file_name,
          scenario_file_id: fRow.scenario_file_id,
        });
      });
      formData.append("job_data", JSON.stringify(submitable));
      return fetch(`${process.env.REACT_APP_BACKEND_URL}api/createjob`, {
        method: "POST",
        body: formData,
      }).then((response) => response.json());
    },
  });
};

export default useJobCreationMutation;
