/* global process */

import { useMutation } from "@tanstack/react-query";
import mockData from "../services/mocks/complete-results";

const useComparisonCompleteMutator = () => {
  return useMutation({
    mutationFn: (comparison_id) => {
      if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
        return mockData;
      }
      return fetch(`${process.env.REACT_APP_BACKEND_URL}api/complete-results?comparison_id=${comparison_id}`, {
        method: "POST",
      }).then((response) => response.json());
    },
  });
};

export default useComparisonCompleteMutator;
