/* global process */
import PolicyCheckerAgGrid from "../ag-grid/PolicyCheckerAgGrid";
import { Box } from "@mui/material";
import useHistoryChecks from "../customHooks/useHistoryChecks";
import { useNavigate } from "react-router-dom";
import TabButtons from "../components/TabButtons";
import { usePolicyCheckerHomeColumnDefs } from "../customHooks/columnDefs/usePolicyCheckerHomeColumnDefs";
import { useIsAuthenticated } from "@azure/msal-react";

const PolicyCheckerHome = () => {
  const { data } = useHistoryChecks();

  const navigate = useNavigate();
  const policyCheckerHomeColumnDefs = usePolicyCheckerHomeColumnDefs();
  const isAuthenticated = useIsAuthenticated();
  if (process.env.REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
    navigate("/login");
    return <div>Invalid access</div>;
  }
  const onRowClicked = (event) => {
    const jobId = event.data.job_id;
    navigate(`/jobdetails/${jobId}`);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div className="tab-container" style={{ marginLeft: "20px" }}>
          <TabButtons />
        </div>
        <div className="button-container"></div>
      </div>
      <Box sx={{ border: "2px solid #2F94CD", margin: "0px 20px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ height: 655, borderRadius: "5px", margin: "10px auto", padding: "15px" }}
        >
          <PolicyCheckerAgGrid
            columnDefs={policyCheckerHomeColumnDefs}
            rowData={data || []}
            onRowClicked={onRowClicked}
          />
        </div>
      </Box>
    </>
  );
};

export default PolicyCheckerHome;
