import { useMemo } from "react";
import dateFormatter from "../../ag-grid/valueFormatter/dateFormatter";
import ResultStatusCellRenderer from "../../ag-grid/cellRenderer/ResultStatusCellRenderer";
import CustomFilter from "../../ag-grid/customFilter/customFilter";

/**
 * Custom hook that provides column definitions for the Policy Checker Home grid.
 *
 * This hook defines a comprehensive set of columns for the main policy checker dashboard,
 * including job details, client information, and status. Each column includes filtering
 * and sorting capabilities.
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - JobId: Unique identifier for each job (filterable, sortable)
 *   - Client: Client name (filterable, sortable)
 *   - Line of Business: Business category (filterable, sortable)
 *   - Issuing Company: Name of the issuer (filterable, sortable)
 *   - Account Manager: Manager name (filterable, sortable)
 *   - Last Updated By: User who last modified the job (filterable, sortable)
 *   - Checks Completed: Scenario name with custom filter (filterable, sortable)
 *   - Status: Job status with custom renderer (filterable, sortable)
 *   - Date Created: Creation date with custom formatting (filterable, sortable, default desc sort)
 *   - Client Code: Client identifier (filterable, sortable)
 *
 * @example
 * const columnDefs = usePolicyCheckerHomeColumnDefs();
 * <AgGridReact columnDefs={columnDefs} />
 */
export const usePolicyCheckerHomeColumnDefs = () => {
  return useMemo(
    () => [
      { field: "job_id", headerName: "JobId", filter: "agTextColumnFilter", sortable: true },
      {
        field: "client",
        headerName: "Client",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "lob_name",
        headerName: "Line of Business",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "issuer_name",
        headerName: "Issuing Company",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "account_manager",
        headerName: "Account Manager",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "user_submitted",
        headerName: "Last Updated By",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "scenario_name",
        headerName: "Checks Completed",
        filter: CustomFilter,
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "status",
        headerName: "Status",
        filter: "agTextColumnFilter",
        sortable: true,
        cellRenderer: ResultStatusCellRenderer,
        maxWidth: 150,
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: "created_at",
        headerName: "Date Created",
        filter: "agDateColumnFilter",
        sortable: true,
        sort: "desc",
        valueFormatter: dateFormatter,
      },
      {
        field: "client_code",
        headerName: "Client Code",
        filter: "agTextColumnFilter",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
      },
    ],
    []
  );
};
