import { SignInButton } from "../components/SignInButton";

export function Login() {
  return (
    <div>
      <div>This application needs you to be logged in to use it.</div>
      <SignInButton />
    </div>
  );
}

export default Login;
