import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import useLobPopulator from "../customHooks/useLobPopulator";

const LobSelect = React.forwardRef(function LobSelect(props, ref) {
  const { isLoading, isError, data = { data: [] } } = useLobPopulator();

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>Error</p>;
  }

  return (
    <Select {...props} value={props.value ?? ""} ref={ref} fullWidth>
      {(data?.data || []).map((option) => (
        <MenuItem key={option.lob_id} value={option.lob_id}>
          {option.lob_name}
        </MenuItem>
      ))}
    </Select>
  );
});

LobSelect.propTypes = {
  value: PropTypes.number,
};

export default LobSelect;
