/* global process */

import mockData from "./mocks/jobs.js";

const fetchActiveChecks = async () => {
  if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData.data);
      }, 1000);
    });
  }
  const response = await (await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/jobs?status=ACTIVE`)).json();
  /**
   response: {
     status: "success",
     message: "Jobs fetched successfully.",
     data: [...]
     status_code: 200
   }
   */
  return response.data;
};
export default fetchActiveChecks;
