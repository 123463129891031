/* global process */
import PolicyCheckerAgGrid from "../ag-grid/PolicyCheckerAgGrid";
import { Box, CircularProgress, Typography } from "@mui/material";
import useActiveChecks from "../customHooks/useActiveChecks";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import TabButtons from "../components/TabButtons";
import { useIsAuthenticated } from "@azure/msal-react";

import { usePolicyCheckerHomeColumnDefs } from "../customHooks/columnDefs/usePolicyCheckerHomeColumnDefs";

const PolicyCheckerHome = () => {
  const { data, isLoading, error } = useActiveChecks();

  const navigate = useNavigate();
  const policyCheckerHomeColumnDefs = usePolicyCheckerHomeColumnDefs();

  const onRowClicked = (event) => {
    const jobId = event.data.job_id;
    navigate(`/jobdetails/${jobId}`);
  };

  const isAuthenticated = useIsAuthenticated();
  if (process.env.REACT_APP_USE_AUTH_BLOCK !== "false" && !isAuthenticated) {
    navigate("/login");
    return <div>Invalid access</div>;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress data-testid="loading-indicator" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error" data-testid="error-message">
          Error loading data: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div className="tab-container" style={{ marginLeft: "20px" }}>
          <TabButtons />
        </div>
        <div className="button-container">
          <ActionButton listeners={[]} />
        </div>
      </div>
      <Box sx={{ border: "2px solid #2F94CD", margin: "0px 20px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ height: 655, borderRadius: "5px", margin: "10px auto", padding: "15px" }}
        >
          <PolicyCheckerAgGrid
            columnDefs={policyCheckerHomeColumnDefs}
            rowData={data || []}
            onRowClicked={onRowClicked}
          />
        </div>
      </Box>
    </>
  );
};

export default PolicyCheckerHome;
