/* global process */
import mockData from "./mocks/file-by-fileid";

const fetchFilesByFileId = async (fileId) => {
  if (process.env.REACT_APP_USE_MOCKED_BACKEND === "true") {
    return Promise.resolve(mockData);
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/job-files-by-fileid?file_id=${fileId}`);
  const data = await response.json();
  return data.data;
};

export default fetchFilesByFileId;
